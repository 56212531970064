import { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Modal from "../../../../../components/Modal/Modal";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import { StyledButton } from "../../../../../components/styled/styled-components";
import {
  IPaymentSessionResponse,
  getPaymentMethodSession,
} from "../../../../order-history/fetch/paymentMethod";
import { OrderSubmitStatuses } from "../../../../../interfaces/enums";
import { PaymentOption } from "../../../payment-selection/sendPaymentSelection";
import { OrderSplitCurrentOrder } from "../../../../../interfaces/interfaces";

interface Props {
  orderAccpacNumber: string | null;
  orderId: number;
  submitStatus: OrderSubmitStatuses | null;
  showSyd?: boolean;
  showMel?: boolean;
  showPer?: boolean;
  showAuk?: boolean;
  showCan?: boolean;
  order: OrderSplitCurrentOrder | null;
  changeModalToShow: (modalToShow: number) => void;
}

const OrderResult = ({
  orderAccpacNumber,
  orderId,
  submitStatus,
  showSyd,
  showMel,
  showPer,
  showAuk,
  showCan,
  order,
  changeModalToShow,
}: Props) => {
  const [paymentSessionUrl, setPaymentSessionUrl] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    const loadPaymentSession = async () => {
      setIsLoading(true);
      const redirection_url = `${window.location.origin}/order-history`;
      const responseSession: IPaymentSessionResponse =
        await getPaymentMethodSession(orderId, redirection_url);
      if (
        responseSession.status === 200 &&
        responseSession.session_url !== null
      ) {
        setPaymentSessionUrl(responseSession.session_url);
      }
      setIsLoading(false);
    };

    if (!paymentSessionUrl) loadPaymentSession();
  }, [paymentSessionUrl, orderId, location.pathname]);

  const renderBankTransferButton = () => (
    <>
    <StyledButton
          onClick={() => history.push(`/order-history?order=${orderId}`)}
        >
          Open order details
    </StyledButton>
    <StyledButton onClick={() => changeModalToShow(7)}>Bank Transfer Info</StyledButton>
    </>
  );

  const renderEmailTransferButton = () => (
    <>
    <StyledButton
          onClick={() => history.push(`/order-history?order=${orderId}`)}
        >
          Open order details
    </StyledButton>
    <StyledButton onClick={() => changeModalToShow(8)}>E-Transfer Info</StyledButton>
    </>
  );

  const renderCCPaymentButton = () => {
    if (paymentSessionUrl !== null) {
      return (
        <>
          <a href={paymentSessionUrl || ""}>
            <StyledButton>Pay order</StyledButton>
          </a>
        </>
      );
    }
    if (isLoading) return <LoadingSpinner />;

    return (
      <>
        <StyledButton onClick={() => history.go(0)}>Back to store</StyledButton>
        <StyledButton
          onClick={() => history.push(`/order-history?order=${orderId}`)}
        >
          Open order details
        </StyledButton>
      </>
    );
  };

  const renderPaymentButton = () => {
    if (isLoading) return <LoadingSpinner />;

    return (
      <>
        <StyledButton onClick={() => history.go(0)}>Back to store</StyledButton>
        <StyledButton
          onClick={() => history.push(`/order-history?order=${orderId}`)}
          >
          Open order details
        </StyledButton>
      </>
    );
  };

  const history = useHistory();

  return (
    <Modal>
      <div className="order-result">
        <div className="main-content">
          {submitStatus === OrderSubmitStatuses.Success ? (
            showCan && order?.payment_method === PaymentOption.payOnline ?
              <div className="success">
                <h1>Your order (#{orderAccpacNumber}) will be processed as soon as <b>payment</b> in full has been made.</h1>
                <div>
                  Please click on the button below to proceed to payment.
                </div>
                <div>
                  {renderCCPaymentButton()}
                </div>
              </div>
            :
            <div className="success">
              <h1>Thank you!</h1>
              <h2>{`Your order (#${orderAccpacNumber}) has been submitted.`}</h2>
              <div className="ship-origin-list">
                <div>Your order will ship from: </div>
                <ol>
                  {showSyd && <li>Sydney</li>}
                  {showMel && <li>Melbourne</li>}
                  {showPer && <li>Perth</li>}
                  {showAuk && <li>Auckland</li>}
                  {showCan && <li>Canada</li>}
                </ol>
              </div>
              <div>
                You can check out the status of your order{" "}
                <Link to={`/order-history?order=${orderId}`}>here.</Link>
              </div>
              <div className="actions">
                {order?.payment_method === PaymentOption.payOnline &&
                  renderCCPaymentButton()}
                {order?.payment_method === PaymentOption.BankTransfer &&
                  renderBankTransferButton()}
                {order?.payment_method === PaymentOption.EmailTransfer &&
                  renderEmailTransferButton()}
                {order?.payment_method === PaymentOption.CreditLimit &&
                  renderPaymentButton()}
              </div>
            </div>
          ) : (
            <div className="fail">
              <div>
                <h2>Sorry, something went wrong.</h2>
                <h2>Please try again.</h2>
              </div>
              <div className="actions">
                <StyledButton onClick={() => history.push(`/order-history?`)}>
                  Open order details
                </StyledButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OrderResult;
