import { formatToCostString } from "../commonfunctions/cost-calculation";
import { subdomainTypes } from "../context/availableSubdomains";
import { OrderDetail, OrderSplitCurrentOrder } from "../interfaces/interfaces";
import { PaymentOption } from "../pages/check-out/payment-selection/sendPaymentSelection";

export enum PaymentDetailsId {
  totalProducts = "totalProducts",
  shippingCost = "shippingCost",
  tax = "Tax",
  subTotal = "subTotal",
  transactionFee = "transactionFee",
  grandTotal = "grandTotal",
  discount = "discount",
}

export interface IDetail {
  id: PaymentDetailsId;
  title: string;
  value: string;
  boldTitle?: boolean;
}

const items = {
  [PaymentDetailsId.totalProducts]: {
    id: PaymentDetailsId.totalProducts,
    title: "Total Products",
    keyObject: "total_products",
  },
  totalProductsAU: {
    id: PaymentDetailsId.totalProducts,
    title: "Total products",
    keyObject: "total_products",
  },
  [PaymentDetailsId.shippingCost]: {
    id: PaymentDetailsId.shippingCost,
    title: "Shipping Cost",
    keyObject: "shipping_cost",
  },
  [PaymentDetailsId.tax]: {
    id: PaymentDetailsId.tax,
    title: "Tax",
    keyObject: "tax",
  },
  [PaymentDetailsId.subTotal]: {
    id: PaymentDetailsId.subTotal,
    title: "Subtotal",
    keyObject: "subtotal",
  },
  [PaymentDetailsId.transactionFee]: {
    id: PaymentDetailsId.transactionFee,
    title: "Transaction Fee",
    keyObject: "transaction_fee",
  },
  [PaymentDetailsId.grandTotal]: {
    id: PaymentDetailsId.grandTotal,
    title: "Grand total",
    keyObject: "grand_total",
    boldTitle: true,
  },
  [PaymentDetailsId.discount]: {
    id: PaymentDetailsId.grandTotal,
    title: "Discount",
    keyObject: "discount",
  },
};

const defaultItems = {
  nz: [items.totalProducts, items.shippingCost, items.Tax],
  au: [items.totalProductsAU, items.shippingCost],
  ca: [items.totalProducts, items.shippingCost, items.Tax],
};

const onlineItems = {
  nz: [items.subTotal, items.transactionFee, items.grandTotal],
  au: [items.transactionFee, items.Tax, items.grandTotal],
  ca: [items.discount, items.grandTotal],
};

const bankTransferItems = {
  nz: [items.grandTotal],
  au: [items.Tax, items.grandTotal],
  ca: [items.discount, items.grandTotal],
};

// MAIN FUNCTION
export const getPaymentDetails = (
  order: OrderDetail | OrderSplitCurrentOrder,
  branch: subdomainTypes | ""
): IDetail[] => {
  if (branch === "") {
    return [];
  }
  type T = keyof typeof defaultItems;
  const defaultDetails = defaultItems[branch as T];
  const customDetails =
    order.payment_method === PaymentOption.payOnline
      ? onlineItems[branch as T]
      : bankTransferItems[branch as T];

  const details: IDetail[] = [...defaultDetails, ...customDetails].map(
    (detail) => {
      type T = keyof typeof order.payment_details;
      const value = `${order.payment_details[detail.keyObject as T]}`;

      return {
        ...detail,
        value: formatToCostString(value),
      };
    }
  );
  return details;
};

// SECOND FUNCTION
export const getPaymentDetailsPaid = (
  order: OrderDetail,
  branch: subdomainTypes | ""
): IDetail[] => {
  if (branch === "") {
    return [];
  }
  type T = keyof typeof defaultItems;
  const defaultDetails = defaultItems[branch as T];
  const customDetails =
    order.payment_method === PaymentOption.payOnline
      ? onlineItems[branch as T]
      : bankTransferItems[branch as T];

  const details: IDetail[] = [...defaultDetails, ...customDetails].map(
    (detail) => {
      type T = keyof typeof order.payment_details_paid;
      const value = `${order.payment_details_paid[detail.keyObject as T]}`;

      return {
        ...detail,
        value: formatToCostString(value),
      };
    }
  );
  return details;
};
